import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthsService } from '../services/auths.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthsService, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let updatedRequest = request;

    if (this.authService.ROLE === 'ADMIN') {
      const adminToken = this.authService.ADMIN_TOKEN_KEY;
      if (adminToken) {
        updatedRequest = request.clone({
          setHeaders: { 'X-Admin-Token': adminToken },
        });
      }
    }

    if (this.authService.ROLE === 'AGENT') {
      const token = this.authService.TOKEN_KEY;
      if (token) {
        updatedRequest = request.clone({
          setHeaders: { 'X-User-Token': token },
        });
      }
    }

    return next.handle(updatedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMsg =
          error.error instanceof ErrorEvent
            ? { Message: `${error.error.message}` }
            : {
                ErrorCode: `${error.status}`,
                Message: `${error.status} : ${error.statusText}`,
              };
        return throwError(() => errorMsg);
      })
    );
  }
}
